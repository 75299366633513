import React from 'react';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { getUmrahSection3Data } from '../../Admin/Home/helper';
import { People as PeopleIcon, Groups as GroupsIcon } from '@mui/icons-material';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Pagination } from '@mui/material';

const Section4 = () => {

    const refArr = React.useRef([]);

    const [data, setData] = React.useState([]);
    const [count, setCount] = React.useState([0, 5]);

    const paginate = (n) => {
        let x = 0, y = 5;
        for(let i = 0; i < n-1; i++){
            x = y;
            y = y + 5;
        }
        setCount([x, y]);
    };

    const onChange = (i) => {
        refArr.current[i].classList?.add('fadeUp');
        refArr.current[i].classList?.remove('anim');
    };

    const fetchData = async() => {
        await getUmrahSection3Data(setData);
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    const filteredData = data?.slice(count[0], count[1]);

    return (

        <section className='umrah-section4 section-padding'>
            {filteredData.map((obj, i) => (
                <ReactVisibilitySensor partialVisibility onChange={e => {
                    if(e) onChange(i);
                }}>
                    <Box key={i} mt={4} width='80%' ref={ref => refArr.current[i]=ref}>
                        <Box width='100%' sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography variant='h6' sx={{ color: '#585858', fontWeight: 500, fontFamily: 'inherit' }}>{obj?.title || ''}</Typography>
                        </Box>
                        <Box mt={2} width='100%' className='dfhbhBB' sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', borderBottom: '2px solid #c49b32' }}>
                            <Box className='reuefJE' sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                <PeopleIcon/>
                                <PeopleIcon sx={{ marginLeft: -0.8 }}/>
                                <Typography variant='subtitle1' sx={{ color: '#585858', fontWeight: 400, fontFamily: 'inherit', ml: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    {obj?.header[0]?.title} | <Typography sx={{ fontWeight: '600', marginLeft: '6px' }}>{obj?.header[0]?.price}</Typography>
                                </Typography>
                            </Box>
                            <Box className='reuefJE' sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                <GroupsIcon/>
                                <Typography variant='subtitle1' sx={{ color: '#585858', fontWeight: 400, fontFamily: 'inherit', ml: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    {obj?.header[1]?.title} | <Typography sx={{ fontWeight: '600', marginLeft: '6px' }}>{obj?.header[1]?.price}</Typography>
                                </Typography>
                            </Box>
                            <Box className='reuefJE' sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                <PeopleIcon/>
                                <Typography variant='subtitle1' sx={{ color: '#585858', fontWeight: 400, fontFamily: 'inherit', ml: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    {obj?.header[2]?.title} | <Typography sx={{ fontWeight: '600', marginLeft: '6px' }}>{obj?.header[2]?.price}</Typography>
                                </Typography>
                            </Box>
                        </Box>
                        <Box width='100%' className='grid-container' pt={4}>
                            {obj?.table?.map((obj2, i2) => (
                                <TableContainer className='table-umrah' key={i2} sx={{ border: '3px solid #dad0be', borderRadius: 2 }}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align='center' sx={{ padding: '10px', fontWeight: '600', backgroundColor: '#7e563d', color: '#FFF' }}>{obj2?.m11}</TableCell>
                                                <TableCell align='center' sx={{ padding: '10px', fontWeight: '600', backgroundColor: '#7e563d', color: '#FFF', borderLeft: '1px solid #dad0be', borderRight: '1px solid #dad0be' }}>{obj2?.m12}</TableCell>
                                                <TableCell align='center' sx={{ padding: '10px', fontWeight: '600', backgroundColor: '#7e563d', color: '#FFF' }}>{obj2?.m13}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align='center' sx={{ padding: '10px', backgroundColor: '#c19358', color: '#FFFFFF' }}>{obj2?.m21}</TableCell>
                                                <TableCell align='center' sx={{ padding: '10px', backgroundColor: '#c19358', color: '#FFFFFF', borderLeft: '1px solid #dad0be', borderRight: '1px solid #dad0be' }}>{obj2?.m22}</TableCell>
                                                <TableCell align='center' sx={{ padding: '10px', backgroundColor: '#c19358', color: '#FFFFFF' }}>{obj2?.m23}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align='center' sx={{ padding: '10px', backgroundColor: '#FFFFFF', color: '#000000', borderBottomLeftRadius: 6, borderBottomRightRadius: 6 }}>{obj2?.m31}</TableCell>
                                                <TableCell align='center' sx={{ padding: '10px', backgroundColor: '#FFFFFF', color: '#000000', borderLeft: '1px solid #dad0be', borderRight: '1px solid #dad0be' }}>{obj2?.m32}</TableCell>
                                                <TableCell align='center' sx={{ padding: '10px', backgroundColor: '#FFFFFF', color: '#000000' }}>{obj2?.m33}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ))}
                        </Box>
                    </Box>
                </ReactVisibilitySensor>
            ))}
            <Box flex={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 4 }}>
                <Pagination count={Math.floor(data?.length/5)+1} variant="outlined" onChange={(e, i) => paginate(i)}/>
            </Box>
        </section>

    );

};

export default React.memo(Section4);