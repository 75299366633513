import { db } from "../../hooks";
import { unparse as convertToCSV } from 'papaparse';
import { arrayRemove, arrayUnion, doc, getDoc, onSnapshot, setDoc, updateDoc, collection, addDoc } from "firebase/firestore";

export const saveHomeData = async(data) => {
    try{
        const docRef = doc(db, 'home', 'data');
        await setDoc(docRef, data);
        alert('Saved!');
    }catch(e){
        alert(`Error: ${e}`);
    }
};

export const getHomeData = async(setData) => {
    try{
        const docRef = doc(db, 'home', 'data');
        const Doc = await getDoc(docRef);
        const data= Doc.data();
        setData(data);
    }catch(e){
        alert(`Error: ${e}`);
    }
};

export const saveAboutData = async(data) => {
    try{
        const docRef = doc(db, 'about', 'data');
        await setDoc(docRef, data);
        alert('Saved!');
    }catch(e){
        alert(`Error: ${e}`);
    }
};

export const getAboutData = async(setData) => {
    try{
        const docRef = doc(db, 'about', 'data');
        const Doc = await getDoc(docRef);
        const data= Doc.data();
        setData(data);
    }catch(e){
        alert(`Error: ${e}`);
    }
};

export const saveHajjData = async(data) => {
    try{
        const docRef = doc(db, 'hajj', 'data');
        await setDoc(docRef, data);
        alert('Saved!');
    }catch(e){
        alert(`Error: ${e}`);
    }
};

export const getHajjData = async(setData) => {
    try{
        const docRef = doc(db, 'hajj', 'data');
        const Doc = await getDoc(docRef);
        const data= Doc.data();
        setData(data);
    }catch(e){
        alert(`Error: ${e}`);
    }
};

export const saveUmrahData = async(data) => {
    try{
        const docRef = doc(db, 'umrah', 'data');
        await setDoc(docRef, data);
        alert('Saved!');
    }catch(e){
        alert(`Error: ${e}`);
    }
};

export const getUmrahData = async(setData) => {
    try{
        const docRef = doc(db, 'umrah', 'data');
        const Doc = await getDoc(docRef);
        const data= Doc.data();
        setData(data);
    }catch(e){
        alert(`Error: ${e}`);
    }
};

export const saveZiyaratData = async(data) => {
    try{
        const docRef = doc(db, 'ziyarat', 'data');
        await setDoc(docRef, data);
        alert('Saved!');
    }catch(e){
        alert(`Error: ${e}`);
    }
};

export const getZiyaratData = async(setData) => {
    try{
        const docRef = doc(db, 'ziyarat', 'data');
        const Doc = await getDoc(docRef);
        const data= Doc.data();
        setData(data);
    }catch(e){
        alert(`Error: ${e}`);
    }
};

export const saveData = async(data) => {
    try{
        const docRef = doc(db, 'data', 'data');
        await setDoc(docRef, data);
        alert('Saved!');
    }catch(e){
        alert(`Error: ${e}`);
    }
};

export const getData = async(setData) => {
    try{
        const docRef = doc(db, 'data', 'data');
        const Doc = await getDoc(docRef);
        const data= Doc.data();
        setData(data);
    }catch(e){
        alert(`Error: ${e}`);
    }
};

export const getNewsLetterData = async(setData) => {
    try{
        const docRef = doc(db, 'data', 'newsletter');
        onSnapshot(docRef, (snap) => {
            const data = snap.data()?.data.slice(1) || [];
            setData(data);
        });
    }catch(e){
        alert(`Error: ${e}`);
    }
};

export const getHajjRegData = async(setData) => {
    try{
        const docRef = doc(db, 'data', 'reg');
        onSnapshot(docRef, (snap) => {
            const data = snap.data()?.data.slice(1) || [];
            setData(data);
        });
    }catch(e){
        alert(`Error: ${e}`);
    }
};

export const getTouchData = async(setData) => {
    try{
        const docRef = doc(db, 'data', 'touch');
        onSnapshot(docRef, (snap) => {
            const data = snap.data()?.data.slice(1) || [];
            setData(data);
        });
    }catch(e){
        alert(`Error: ${e}`);
    }
};

export const delHajjRegClient = async(item) => {
    try{
        const Data = {
            data: arrayRemove(...[item]),
        };
        const docRef = doc(db, 'data', 'reg');
        await updateDoc(docRef, Data);
        alert('Client was removed');
    }catch(e){
        alert(`Error: ${e}`);
    }
};

export const delTouchClient = async(item) => {
    try{
        const Data = {
            data: arrayRemove(...[item]),
        };
        const docRef = doc(db, 'data', 'touch');
        await updateDoc(docRef, Data);
        alert('Client was removed');
    }catch(e){
        alert(`Error: ${e}`);
    }
};

export const delNewsLetterClient = async(email) => {
    try{
        const Data = {
            data: arrayRemove(...[email]),
        };
        const docRef = doc(db, 'data', 'newsletter');
        await updateDoc(docRef, Data);
        alert('Client was removed');
    }catch(e){
        alert(`Error: ${e}`);
    }
};

export const subToNewsLetter = async(email, from) => {
    try{
        const Data = {
            data: arrayUnion(...[email]),
        };
        const docRef = doc(db, 'data', 'newsletter');
        await updateDoc(docRef, Data);
        notifyByEmail(`Hey Alif Tours 👋\n${email} subscribed to your newsletter!`, from);
        alert('Congratulations! You have subscribed to our newsletter 🎉');
    }catch(e){
        console.log(e);
        alert('Error! see console for logs');
    }
};

export const regForHajj = async(data, from) => {
    try{
        const Data = {
            data: arrayUnion(...[data]),
        };
        const docRef = doc(db, 'data', 'reg');
        await updateDoc(docRef, Data);
        let prettyData = '';
        for (const [key, value] of Object.entries(data)) {
            prettyData += `${key}: ${value}\n`;
        }
        notifyByEmail(`Hey Alif Tours 👋\nSomeone just registered for Hajj!\nCheck the below data for more:\n\n${prettyData}\n\n`, from);
        alert('Congratulations! You have successfully registered 🎉');
    }catch(e){
        console.log(e);
        alert('Error! see console for logs');
    }
};

export const getInTouch = async(data, from) => {
    try{
        const Data = {
            data: arrayUnion(...[data]),
        };
        const docRef = doc(db, 'data', 'touch');
        await updateDoc(docRef, Data);
        let prettyData = '';
        for (const [key, value] of Object.entries(data)) {
            prettyData += `${key}: ${value}\n`;
        }
        notifyByEmail(`Hey Alif Tours 👋\nSomeone wants to get in touch with you!\nCheck the below data for more:\n\n${prettyData}\n\n`, from);
        alert('Congratulations! Your message has been successfully sent 🎉');
    }catch(e){
        console.log(e);
        alert('Error! see console for logs');
    }
};

export const getAdminData = async(setData) => {
    try{
        const docRef = doc(db, 'data', 'admin');
        onSnapshot(docRef, (snap) => {
            const data = snap.data()?.data.slice(1) || [];
            setData(data);
        });
    }catch(e){
        alert(`Error: ${e}`);
    }
};

export const addAdminData = async(data) => {
    try{
        const Data = {
            data: arrayUnion(...[data]),
        };
        const docRef = doc(db, 'data', 'admin');
        await updateDoc(docRef, Data);
        alert('New admin was added 🎉');
    }catch(e){
        console.log(e);
        alert('Error! see console for logs');
    }
};

export const DownloadAsCSV = async(data, fileName) => {
    try{
        const csv = convertToCSV({ data, header: true });
        const blob = new Blob([csv], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${fileName}.csv`;
        link.click();
    }catch(e){
        alert(`Cannot download CSV: ${e}`);
    }
};

const notifyByEmail = async(sub, email) => {
    try{
        const mailRef = collection(db, 'mail');
        const Data = {
            to: email,
            message: {
                subject: 'Hey Alif Tours 👋 You have new activity' || '',
                text: sub || '',
                html: '',
            }
        };
        await addDoc(mailRef, Data);
    }catch(e){
        console.log(e);
    }
};

export const saveSEOData = async(data) => {
    try{
        const docRef = doc(db, 'seo', 'data');
        await setDoc(docRef, data);
        alert('Saved!');
    }catch(e){
        alert(`Error: ${e}`);
    }
};

export const getSEOData = async(setData) => {
    try{
        const docRef = doc(db, 'seo', 'data');
        const Doc = await getDoc(docRef);
        const data = Doc.data();
        setData(data);
    }catch(e){
        alert(`Error: ${e}`);
    }
};

export const saveUmrahSection3Data = async(data) => {
    try{
        const docRef = doc(db, 'umrah', 'section3');
        await setDoc(docRef, {
            data: data, 
        });
        alert('Saved!');
    }catch(e){
        alert(`Error: ${e}`);
    }
};

export const getUmrahSection3Data = async(setData) => {
    try{
        const docRef = doc(db, 'umrah', 'section3');
        const Doc = await getDoc(docRef);
        const data = Doc.data().data;
        setData(data);
    }catch(e){
        alert(`Error: ${e}`);
    }
};