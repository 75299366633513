import React from 'react';
import { saveUmrahSection3Data, getUmrahSection3Data } from '../helper';
import { People as PeopleIcon, Groups as GroupsIcon, ChangeCircle } from '@mui/icons-material';
import { Box, Typography, Button, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, LinearProgress } from '@mui/material';

const header = [{ title: 'QUAD', price: '0' }, { title: 'TRIPLE', price: '0' }, { title: 'TWIN',price: '0' }];
const table = [{ m11: 'Group', m12: '-', m13: '-', m21: 'Check In', m22: '-', m23: '-', m31: 'Check Out', m32: '-', m33: '-' }];

export default function Section3() {

    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState([]);

    const addSection = () => {
        setData(state => [...state, {
            title: 'TITLE', 
            header: header, 
            table: table, 
        }]);
    };

    const removeSection = (i) => {
        setData(state => state.filter((item, index) => index !== i));
    };

    const changeTitle = (i) => {
        const value = prompt('Enter your title here') || '';
        if(value.trim().length === 0) return;
        setData(state => [
            ...state.map((item, index) => 
                index === i ? { ...item, title: value.trim() } : item
            )
        ]);
    };

    const addTable = (i) => {
        setData(state => [
            ...state.map((item, index) => 
                index === i ? {
                    header: item.header, 
                    table: [...item.table, ...table],
                } : item
            )
        ]);
    };

    const removeTable = (i, x) => {
        setData(state => [
            ...state.map((item, index) => 
                index === i ? {
                    header: item.header, 
                    table: [...item.table.filter((item2, index2) => index2 !== x)]
                } : item
            )
        ]);
    };

    const addHeaderValue = (i, x) => {
        const value = prompt('Enter your price here') || '';
        if(value.trim().length === 0) return;
        setData(state => [
            ...state.map((item, index) => 
                index === i ? {
                    header: [
                        ...item.header.map((item2, index2) => 
                            index2 === x ? {
                                title: item2.title, 
                                price: value.trim(), 
                            } : item2
                        )
                    ], 
                    table: item.table, 
                } : item
            )
        ]);
    };

    const addTableValue = (i, x, obj) => {
        const value = prompt('Enter your value here') || '';
        if(value.trim().length === 0) return;
        setData(state => [
            ...state.map((item, index) => 
                index === i ? {
                    header: item.header, 
                    table: [
                        ...item.table.map((item2, index2) => 
                            index2 === x ? {
                                ...item2, 
                                [obj]: value.trim(),  
                            } : item2
                        )
                    ], 
                } : item
            )
        ]);
    };

    const fetchData = async() => {
        setLoading(true);
        await getUmrahSection3Data(setData);
        setLoading(false);
    };

    const saveData = async(DATA) => {
        setLoading(true);
        await saveUmrahSection3Data(DATA);
        setLoading(false);
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    return (

        <Box width='100%'>
            <Box height={2}/>
            {loading?<LinearProgress sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 10000 }}/>:null}
            {data.map((obj, i) => (
                <Box key={i} mt={6} width='100%' sx={{ backgroundColor: '#f0e9c5', padding: 6, borderRadius: 10 }}>
                    <IconButton onClick={() => changeTitle(i)}>
                        <Typography variant='h6' sx={{ color: '#585858', fontWeight: 500, fontFamily: 'inherit', ml: 2 }}>{obj.title || 'TITLE'}</Typography>
                    </IconButton>
                    <Box width='100%' sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', borderBottom: '1px solid #FFFFFF', paddingBottom: 2 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            <PeopleIcon/>
                            <PeopleIcon sx={{ marginLeft: -0.8 }}/>
                            <Typography variant='subtitle1' sx={{ color: '#585858', fontWeight: 400, fontFamily: 'inherit', ml: 2 }}>{obj.header[0].title} | {obj.header[0].price}</Typography>
                            <IconButton sx={{ ml: 2 }} onClick={() => addHeaderValue(i, 0)}>
                                <ChangeCircle/>
                            </IconButton>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            <GroupsIcon/>
                            <Typography variant='subtitle1' sx={{ color: '#585858', fontWeight: 400, fontFamily: 'inherit', ml: 2 }}>{obj.header[1].title} | {obj.header[1].price}</Typography>
                            <IconButton sx={{ ml: 2 }} onClick={() => addHeaderValue(i, 1)}>
                                <ChangeCircle/>
                            </IconButton>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            <PeopleIcon/>
                            <Typography variant='subtitle1' sx={{ color: '#585858', fontWeight: 400, fontFamily: 'inherit', ml: 2 }}>{obj.header[2].title} | {obj.header[2].price}</Typography>
                            <IconButton sx={{ ml: 2 }} onClick={() => addHeaderValue(i, 2)}>
                                <ChangeCircle/>
                            </IconButton>
                        </Box>
                    </Box>
                    <Typography variant='subtitle1' sx={{ color: '#585858', fontWeight: 400, fontFamily: 'inherit', ml: 2, mt: 4 }}>Click on table cell to edit table contents</Typography>
                    <Box width='100%' sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', mt: 4, justifyContent: 'space-evenly' }}>
                        {obj.table.map((obj2, i2) => (
                            <Box key={i2}>
                                <TableContainer key={i2} sx={{ minWidth: 460, border: '1px solid #FFFFFF', borderRadius: 4, backgroundColor: '#f4ebc2' }}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align='center' sx={{ cursor: 'pointer' }} onClick={() => addTableValue(i, i2, 'm11')}>{obj2.m11}</TableCell>
                                                <TableCell align='center' sx={{ cursor: 'pointer' }} onClick={() => addTableValue(i, i2, 'm12')}>{obj2.m12}</TableCell>
                                                <TableCell align='center' sx={{ cursor: 'pointer' }} onClick={() => addTableValue(i, i2, 'm13')}>{obj2.m13}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align='center' sx={{ cursor: 'pointer' }} onClick={() => addTableValue(i, i2, 'm21')}>{obj2.m21}</TableCell>
                                                <TableCell align='center' sx={{ cursor: 'pointer' }} onClick={() => addTableValue(i, i2, 'm22')}>{obj2.m22}</TableCell>
                                                <TableCell align='center' sx={{ cursor: 'pointer' }} onClick={() => addTableValue(i, i2, 'm23')}>{obj2.m23}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align='center' sx={{ cursor: 'pointer' }} onClick={() => addTableValue(i, i2, 'm31')}>{obj2.m31}</TableCell>
                                                <TableCell align='center' sx={{ cursor: 'pointer' }} onClick={() => addTableValue(i, i2, 'm32')}>{obj2.m32}</TableCell>
                                                <TableCell align='center' sx={{ cursor: 'pointer' }} onClick={() => addTableValue(i, i2, 'm33')}>{obj2.m33}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Button 
                                key={i2}
                                variant="contained" 
                                onClick={() => removeTable(i, i2)} 
                                sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 2, mb: 2 }}>Remove this table</Button>
                            </Box>
                        ))}
                    </Box>
                    <Button 
                    variant="contained" 
                    onClick={() => addTable(i)} 
                    sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 6, ml: 9 }}>Add new table</Button>
                    <Button 
                    variant="contained" 
                    onClick={() => removeSection(i)} 
                    sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 6, ml: 4 }}>Remove this section</Button>
                </Box>
            ))}
            <Button 
            variant="contained" 
            onClick={addSection} 
            sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 6 }}>Add new section</Button>
            <Button 
            variant="contained" 
            onClick={() => saveData(data)} 
            sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 6, ml: 6 }}>Save the changes</Button>
            <Typography variant='subtitle1' sx={{ color: '#585858', fontWeight: 400, fontFamily: 'inherit', mt: 2 }}>Make sure you save the changes here too</Typography>
        </Box>

    );

};